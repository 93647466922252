import React from 'react'
import { Flex, Image } from 'antd'
import styled from 'styled-components'

type IconProps = {
  name: string
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement>, id?: string) => void
  width?: number
  height?: number
  showCursor?: boolean
}

const Icon = (props: IconProps) => {
  const { name, className, onClick, width, height, showCursor } = props
  return (
    <Flex
      className={className}
      onClick={onClick}
    >
      <IconImage
        alt={`icon_${name}`}
        src={`/Icons/${name}.svg`}
        width={width}
        height={height}
        showCursor={Boolean(showCursor)}
      />
    </Flex>
  )
}

export default Icon

const IconImage = styled(Image)<{ showCursor: boolean }>`
  ${props => props.showCursor && 'cursor: pointer'}
`
