import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Dna } from 'react-loader-spinner';
import { auth } from '../Auth/firebaseAuth'
import { useAppDispatch } from '../redux/utils/hooks'
import { requestUserInfo } from '../redux/auth/authSlice'

const ProtectedRoutes: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const channel = new BroadcastChannel('user-presence-status-channel');
    channel.addEventListener('message', (e: any) => {
      if (e.data.presence_status === 'INACTIVE') {
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    });

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        await dispatch(requestUserInfo({ email: user.email as string }));
      } else {
        localStorage.removeItem('user');
        setUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
      channel.close(); 
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div className="loader-effects">
        <Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperClass="dna-wrapper" />
      </div>
    );
  }

  return user ? <Outlet/> :<Navigate to ='/'/>;
};

export default ProtectedRoutes;