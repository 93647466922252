import { useState, Suspense, useEffect, useMemo } from 'react'
import { Button, Flex, Layout, Typography } from 'antd'
import '../style/globals.css'
import { FullScreenSpinner, ButtonSpinner } from '../components/shared/CustomSpinners'
import styled from 'styled-components'
import Viewer from '../components/Viewer'
import { Content } from 'antd/es/layout/layout'
import FileUploader from '../components/osteocheck/UploadFile'
import { AI_SERVICE_URL, CORE_PLATFORM_URL, TextColors } from '../utils/constants'
import { auth } from '../Auth/firebaseAuth'
import { UploadFile } from 'antd/lib/upload'
import { useNavigate } from 'react-router-dom'
import PatientDetails from '../components/cases/PatientDetails'
import { ActionTypes, requestTakeAction } from '../redux/reports/reportSlice'
import { useAppDispatch, useAppSelector } from '../redux/utils/hooks'
import { theme } from '../components/shared/theme'
import { Button as AntButton } from 'antd'
import OverlayLoader from '../components/shared/OverlayLoader'
import { isInFlightPending } from '../utils/helper'
import { InFlightNames } from '../utils/types'
import OverlayModal from '../components/shared/OverlayModal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { StudyStatuses } from '../redux/cases/caseSlice'

let resize = false

function Osteocheck(data: any) {
  const dispatch = useAppDispatch()
  const { inFlights } = useAppSelector(state => state.report)

  const { studyData, viewer } = data
  const [hideImageViewer, setHideImageViewer] = useState<boolean>(false)
  const [leftPaneWidth, setLeftPaneWidth] = useState<number>(0)
  const [userEmail, setUserEmail] = useState('')
  const [selectedFile, setSelectedFile] = useState<UploadFile | null>(null)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setLeftPaneWidth(screen.width / 2 - 1)
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', e => {
      if (resize && e.clientX > 400 && e.clientX < screen.width - 430) {
        setLeftPaneWidth(e.clientX)
      }
    })

    window.addEventListener('mouseup', () => {
      resize = false
      setHideImageViewer(false)
    })

    return () => {
      window.removeEventListener('mousemove', () => {})

      window.removeEventListener('mouseup', () => {})
    }
  }, [])

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    }
  }, [])

  const handleToggleResize = () => {
    resize = true
    setHideImageViewer(true)
  }

  const isBlockUserModalOpen = useMemo(() => {
    return [StudyStatuses.NON_REPORTABLE, StudyStatuses.REPORTABLE, StudyStatuses.IN_POOL].includes(studyData?.status)
  }, [studyData])

  const getBlockUserModalText = () => {
    if (studyData?.status === StudyStatuses.NON_REPORTABLE) {
      return 'This case no longer exists for review. This case is skipped'
    } else if (studyData?.status === StudyStatuses.IN_POOL) {
      return 'This case is not assigned to user'
    }
    return 'This case has already been reported'
  }

  const handleNonReportableButtonClick = async () => {
    await dispatch(
      requestTakeAction({
        action: ActionTypes.NON_REPORTABLE,
        study_id: studyData?.study_id,
        findings: [],
        email: userEmail,
      })
    )
    window.close()
    navigate('/Aiflow')
  }

  const handleReportButtonClick = () => {
    setIsLoading(true)
    if (selectedFile) {
      const file = selectedFile?.originFileObj
      const formData = new FormData()
      formData.append('file', file as string | Blob)
      formData.append('study_id', studyData?.study_id)
      formData.append('rad_id', studyData?.rad_id)
      formData.append('report_title', 'XRAY Osteocheck')
      formData.append('rule', JSON.stringify(studyData?.rules?.[0]))

      fetch(`${CORE_PLATFORM_URL}/report/complete-osteocheck`, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data)

          if (data?.reports?.[0]?.json) {
            const apiData = {
              result: {
                action: 'report',
                study_id: studyData?.study_id,
                json: data?.reports?.[0]?.json,
                email: userEmail,
              },
            }
            fetch(`${AI_SERVICE_URL}/user/action`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(apiData),
            })
              .then(response => response.json())
              .then(data => {
                console.log('API Response:', data)
                setIsLoading(false)
                window.close()
              })
              .catch(error => {
                navigate('/error')
                console.error('Error:', error)
              })
          } else {
            navigate('/error')
          }
        })
        .catch(error => {
          navigate('/error')
          console.error('Error:', error)
        })
    }
  }

  const handleSelectFile = (file: UploadFile | null) => {
    setSelectedFile(file)
  }

  const renderEditor = () => {
    return (
      <EditorWrapperContainer>
        <StyledEditor>
          <FileUploader handleSelectFile={handleSelectFile} />
        </StyledEditor>
      </EditorWrapperContainer>
    )
  }

  const renderEditorFooter = () => {
    return (
      <EditorFooterContainer>
        <StyledSkipButton onClick={handleNonReportableButtonClick}>Non Reportable</StyledSkipButton>
        <StyledButton
          disabled={!selectedFile || isLoading}
          onClick={handleReportButtonClick}
        >
          {isLoading ? <ButtonSpinner /> : 'Submit'}
        </StyledButton>
      </EditorFooterContainer>
    )
  }

  const renderSplitView = () => {
    return (
      <SplitViewContainer leftPane={leftPaneWidth}>
        <ViewerContainer>
          <SplitBlurDiv isBlur={hideImageViewer}>
            <Viewer
              hide={hideImageViewer}
              viewer={viewer}
            />
          </SplitBlurDiv>
        </ViewerContainer>
        <ResizeDragContainer onMouseDown={handleToggleResize}>
          <ResizeDragHandler resize={resize} />
        </ResizeDragContainer>
        <StyledEditorContainer>
          <PatientDetails patientDetails={studyData} />
          {renderEditor()}
          {renderEditorFooter()}
        </StyledEditorContainer>
      </SplitViewContainer>
    )
  }

  return (
    <StyledLayout>
      <OverlayLoader
        isLoading={isInFlightPending(inFlights, InFlightNames.requestTakeActionInFlight)}
        text={'Reporting...'}
      />
      <OverlayModal
        isModalOpen={isBlockUserModalOpen}
        content={
          <Typography.Title
            level={5}
            style={{ margin: 0, padding: '1rem', textAlign: 'center' }}
          >
            <Flex
              gap='small'
              justify='center'
            >
              <ExclamationCircleOutlined style={{ color: theme.colors.warning }} />
              {getBlockUserModalText()}
            </Flex>
          </Typography.Title>
        }
      />
      <ContentContainer>
        <Suspense fallback={<FullScreenSpinner />}>{renderSplitView()}</Suspense>
      </ContentContainer>
    </StyledLayout>
  )
}

export default Osteocheck

type SplitViewContainerProps = {
  leftPane: number
}

type SplitBlurDivType = {
  isBlur: boolean
}

type ResizeDragType = {
  resize: boolean
}

type ScreenType = {
  isSplitScreen?: boolean
}

const SplitViewContainer = styled.div<SplitViewContainerProps>`
  display: grid;
  grid-template-columns: ${props => `${props.leftPane}px 1px auto`};
  grid-template-rows: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`
const ViewerContainer = styled.div`
  overflow: auto;
  position: relative;
  height: 100%;
`

const SplitBlurDiv = styled.div<SplitBlurDivType>`
  background: ${props => props.theme.colors.black};
  filter: ${props => (props.isBlur ? 'blur(100px)' : 'none')};
  height: 100%;
`

const ResizeDragContainer = styled.div`
  height: inherit;
  z-index: 2;
  position: relative;
`

const ResizeDragHandler = styled.div<ResizeDragType>`
  background: ${props => props.theme.colors.ternaryDark};
  border: 1.5px solid ${props => props.theme.colors.ternary};
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: ${props => (props.resize ? 'grabbing' : 'grab')};
  height: 7rem;
  left: 0;
  position: absolute;
  top: calc(50% - 3.5rem);
  width: 1rem;
`

const StyledEditorContainer = styled.div<ScreenType>`
  background-color: 'white';
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const EditorWrapperContainer = styled.div`
  height: 100%;
  overflow: auto;
`
const StyledEditor = styled.div`
  height: calc(100vh - 4rem);
  overflow: hidden;
`

const EditorFooterContainer = styled(Flex)`
  box-shadow: 0 0 20px ${props => props.theme.boxShadows.buttonBoxShadow}64;
  gap: 1rem;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem 1rem;
  height: 4rem;
  bottom: 0;
  right: 0;
`

const StyledSubmitButton = styled(Button)`
  width: 8rem;
`

export const StyledLayout = styled(Layout)`
  height: 99vh;
  background-color: ${props => props.theme.colors.appBackground};
`

export const ContentContainer = styled(Content)`
  position: relative;
`

const StyledButton = styled(AntButton)`
  background-color: ${theme.colors.black} !important;
  color: ${TextColors.appBackground} !important;
  padding: 1.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-width: 8.5rem;
`

const StyledSkipButton = styled(StyledButton)`
  background-color: ${theme.colors.appBackground} !important;
  color: ${TextColors.black} !important;
`
