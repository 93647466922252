import { Button, Flex, Form, InputNumber, Space, Spin, Switch } from 'antd'
import { useEffect } from 'react'
import { Typography } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { theme } from '../../components/shared/theme'
import { useAppDispatch, useAppSelector } from '../../redux/utils/hooks'
import { requestGetSettings, requestUpdateSettings, SettingsKey } from '../../redux/settings/settingSlice'
import { isInFlightPending } from '../../utils/helper'
import { InFlightNames } from '../../utils/types'
import React from 'react'

const Settings: React.FC = () => {
  const dispatch = useAppDispatch()
  const { inFlights, settings } = useAppSelector(state => state.settings)

  const { Text } = Typography

  const toggleCrucialFlow = async (data: { key: SettingsKey; value: string }) => {
    await dispatch(requestUpdateSettings(data))
  }

  useEffect(() => {
    dispatch(requestGetSettings())
  }, [])

  return (
    <SettingContainer>
      {isInFlightPending(inFlights, InFlightNames.requestGetSettingsInFlight) ? (
        <Flex justify='center'>
          <Spin />
        </Flex>
      ) : (
        <Space direction='vertical'>
          <Flex gap='middle'>
            <Text>Crucial flow : </Text>
            <StyledSwitch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={settings?.is_rad_auto_assign_enabled == 'true'}
              checked={settings?.is_rad_auto_assign_enabled === 'true'}
              onChange={(checked: boolean) =>
                toggleCrucialFlow({
                  key: SettingsKey.IS_RAD_AUTO_ASSIGN_ENABLED,
                  value: checked === true ? 'true' : 'false',
                })
              }
              loading={isInFlightPending(inFlights, InFlightNames.requestUpdateSettingsInFlight)}
            />
          </Flex>

          <Form
            onFinish={data => {
              toggleCrucialFlow({
                key: SettingsKey.SPECIAL_CASE_MAX_COUNT,
                value: data?.special_case_max_count?.toString(),
              })
            }}
            autoComplete='off'
          >
            <Flex>
              <Form.Item<{ special_case_max_count: number }>
                label='Special case pool threshold'
                name={SettingsKey.SPECIAL_CASE_MAX_COUNT}
                rules={[{ required: true, message: 'Please fill the value!' }]}
              >
                <InputNumber
                  size='small'
                  min={0}
                  defaultValue={+(settings?.special_case_max_count ?? 3)}
                  disabled={isInFlightPending(inFlights, InFlightNames.requestUpdateSettingsInFlight)}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  htmlType='submit'
                  loading={isInFlightPending(inFlights, InFlightNames.requestUpdateSettingsInFlight)}
                >
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Space>
      )}
    </SettingContainer>
  )
}

export default Settings

const StyledSwitch = styled(Switch)<{ checked: boolean }>`
  .ant-switch-inner {
    background-color: ${props => (props.checked ? theme.colors.success : theme.colors.timerWarning)} !important;
  }
`
const SettingContainer = styled.div``
