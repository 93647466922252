import React from 'react'
import { Card, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

type FindingsCardProps = {
  content: string
  index?: number
  removeContent: (index?: number) => void
  disabled?: boolean
}

const FindingsCard: React.FC<FindingsCardProps> = ({ content, index, removeContent, disabled = false }) => {
  return (
    <StyledCard key={index}>
      <StyledTextContainer>
        <StyledText>{content}</StyledText>
      </StyledTextContainer>
      <RemoveIcon
        onClick={() => !disabled && removeContent(index)}
        disabled={disabled}
      />
    </StyledCard>
  )
}

export default FindingsCard

const StyledCard = styled(Card)`
  width: fit-content;
  border-radius: 1.1rem;
  background-color: #dee2e6;
  .ant-card-body {
    padding: 0.25rem 0.4rem !important;
    display: flex;
  }
  margin: 0.2rem 0.4rem;
`

const StyledTextContainer = styled.div`
  width: fit-content;
  margin: 0 0.5rem;
`

const StyledText = styled(Typography.Text)`
  font-size: 1.1rem;
  color: #495057;
`

const RemoveIcon = styled(CloseCircleOutlined)<{ disabled: boolean }>`
  font-size: 1.1rem;
  margin-left: 0.5rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
