import { DatePicker, Flex, Spin, Table, TableProps, message } from 'antd'
import styled from 'styled-components'
import { FunctionComponent, useEffect, useState } from 'react'
import { AI_SERVICE_URL } from '../../utils/constants'
import { Button, Tag, Typography } from 'antd'
import { css } from 'styled-components'
import { ColumnsType } from 'antd/lib/table'
import axios from 'axios'
import ReworkActionModal from '../../components/analytics/ReworkActionModal'
import dayjs from 'dayjs'

const ReworkStudies: React.FC = () => {
  const [data, setData] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [studyId, setStudyId] = useState(0)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [searchDate, setSearchDate] = useState<string>(() => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    return yesterday.toLocaleString('en-CA', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  })

  const { Text } = Typography

  const OrderColumns: () => ColumnsType<any> = () => {
    return [
      {
        title: 'Study ID',
        dataIndex: 'study_id',
        align: 'center',
        render: (_, row) => {
          return (
            <a
              href={`https://admin.5cnetwork.com/cases/${row?.study_id}`}
              target='_blank'
            >
              {row?.study_id}{' '}
            </a>
          )
        },
      },
      {
        title: 'Rework Reason',
        dataIndex: 'reason',
        render: (_, record) => {
          return (
            <ColumnWidth width={20}>
              <Text ellipsis={{ tooltip: true }}>{record?.rework_reason}</Text>
            </ColumnWidth>
          )
        },
      },
      {
        title: 'Comment',
        dataIndex: 'ai_reason',
        render: (_, record) => {
          return (
            <ColumnWidth width={20}>
              <Text ellipsis={{ tooltip: true }}>{record?.ai_reason ? record?.ai_reason : '-'}</Text>
            </ColumnWidth>
          )
        },
      },
      {
        title: <span>Status</span>,
        dataIndex: 'action',
        align: 'center',
        render: (status, rowData: any) => {
          return (
            <>
              <ColumnWidth minWidth={10}>
                {status ? (
                  <Tag color={status === 'VALID' ? 'green' : 'volcano'}>{status.toUpperCase()}</Tag>
                ) : (
                  <Button
                    size={'small'}
                    onClick={() => {
                      setIsOpen(true)
                      setStudyId(rowData?.study_id)
                    }}
                  >
                    Take Action
                  </Button>
                )}
              </ColumnWidth>
            </>
          )
        },
      },
    ]
  }

  const handleSubmitReason = async (study_id: number, reason: string, action: string) => {
    setIsActionLoading(true)
    try {
      await axios.post(
        `${AI_SERVICE_URL}/analytics/rework-action`,
        {
          study_id,
          reason,
          action,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      setIsActionLoading(false)
      setIsOpen(false)
      setData((prevState: any) =>
        prevState?.map((item: any) =>
          item.study_id === studyId
            ? {
                ...item,
                action,
                ai_reason: reason,
              }
            : item
        )
      )
      messageApi.open({
        type: 'success',
        content: 'Rework action taken successfully',
      })
    } catch (error) {
      setIsActionLoading(false)
      setIsOpen(false)
      messageApi.open({
        type: 'error',
        content: 'Rework action failed!',
      })
    }
  }

  const columns = OrderColumns()

  const getReworkCases = async (selectedDate: any) => {
    const formattedDate = selectedDate
    const apiURL = `${AI_SERVICE_URL}/analytics/rework-cases?date=${formattedDate}`

    try {
      const response = await axios.get(apiURL)
      const sortedData = response.data.sort((a: { created_at: any }, b: { created_at: any }) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
      setData(sortedData)
    } catch (error) {
      setIsPageLoading(false)
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    setIsPageLoading(true)
    if (searchDate) {
      getReworkCases(searchDate).then(data => setIsPageLoading(false))
    }
  }, [searchDate])

  return (
    <Spin spinning={isPageLoading}>
      {contextHolder}
      <Flex
        justify='end'
        style={{ marginBottom: '1rem' }}
      >
        <DatePicker
          defaultValue={dayjs(searchDate, 'YYYY-MM-DD')}
          onChange={(_, date: any) => setSearchDate(date)}
          needConfirm
        />
      </Flex>

      <ReworkActionModal
        study_id={studyId}
        is_open={isOpen}
        is_loading={isActionLoading}
        onSubmit={handleSubmitReason}
        onCancel={() => {
          setIsOpen(false)
        }}
      />

      <CustomTable
        columns={columns}
        dataSource={data}
      />
    </Spin>
  )
}

export default ReworkStudies

const CustomTable: FunctionComponent<TableProps<any>> = styled(Table)``

const ColumnWidth = styled.div<{ minWidth?: number; maxWidth?: number; width?: number }>`
  ${props => {
    if (props.minWidth) {
      return css`
        min-width: ${props.minWidth}rem;
        position: relative;
      `
    }
    if (props.maxWidth) {
      return css`
        max-width: ${props.maxWidth}rem;
        position: relative;
      `
    }
    if (props.width) {
      return css`
        width: ${props.width}rem;
        position: relative;
      `
    }
  }}
`
