import { combineReducers } from '@reduxjs/toolkit'
import auth, { AuthState } from './auth/authSlice'
import cases, { CaseState } from './cases/caseSlice'
import report, { ReportState } from './reports/reportSlice'
import settings, { SettingsState } from './settings/settingSlice'

export type ApplicationState = {
  auth: AuthState
  cases: CaseState
  report: ReportState
  settings: SettingsState
}
const rootReducer = combineReducers({
  auth,
  cases,
  report,
  settings,
})

export default rootReducer
