import React, { useState } from 'react'
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import type { UploadProps, UploadFile } from 'antd/lib/upload'
import { Flex, message, Upload } from 'antd'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

const { Dragger } = Upload

interface props {
  handleSelectFile: (file: UploadFile | null) => void
}

const FileUploader = (Fileprops: props) => {
  const { handleSelectFile } = Fileprops
  const [previewFile, setPreviewFile] = useState<UploadFile | null>(null)
  const [previewOpen, setPreviewOpen] = useState(false)

  const handlePreviewClose = () => {
    setPreviewFile(null)
    setPreviewOpen(false)
    handleSelectFile(null)
  }

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.pdf',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status, originFileObj } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      } else if (status === 'removed') {
        setPreviewFile(null)
        setPreviewOpen(false)
      } else if (originFileObj && originFileObj.type === 'application/pdf') {
        setPreviewFile(info.file)
        setPreviewOpen(true)
        handleSelectFile(info.file)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  return (
    <>
      {!previewOpen && (
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        </Dragger>
      )}
      {previewOpen && previewFile && previewFile.originFileObj && (
        <>
          <Flex justify='flex-end'>
            <DeleteOutlined
              style={{
                fontSize: '1.2rem',
                color: 'white',
                backgroundColor: 'red',
                padding: '0.5rem',
                borderRadius: '0.5rem',
              }}
              onClick={handlePreviewClose}
            />
          </Flex>
          <DocViewer
            documents={[{ uri: URL.createObjectURL(previewFile.originFileObj), fileName: previewFile.name }]}
            pluginRenderers={DocViewerRenderers}
          />
        </>
      )}
    </>
  )
}

export default FileUploader
