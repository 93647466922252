
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { AI_SERVICE_URL } from '../utils/constants'

export enum ApiRequestType {
  GET = 'get',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
  POST = 'post',
}

export type AxiosOptionType = { setHeaderToken?: boolean } & AxiosRequestConfig

const getAxiosConfig = (baseURL = AI_SERVICE_URL): AxiosRequestConfig => ({
  baseURL,
  timeout: 60000,
})

// export const setHeader = async (instance: AxiosInstance) => {
//   instance.interceptors.request.use(
//     async conf => {
//       // const session = await Auth.currentSession()
//       // conf.headers['idToken'] = session.getIdToken().getJwtToken()
//       // conf.headers['accessToken'] = session.getAccessToken().getJwtToken()
//       // return conf
//     },
//     error => {
//       console.error(error)
//       return Promise.reject(error)
//     }
//   )
// }

const request = (method: ApiRequestType, url: string, options: AxiosOptionType) => {
  const instance: AxiosInstance = axios.create(getAxiosConfig(options?.baseURL))

  instance.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: AxiosError) => {
      switch (error?.response?.status) {
        case 401: {
          console.error('error 401 : user not authenticated!')
          break
        }
        case 403: {
          console.error('error 403 : user not authorised!')
          break
        }
        default: {
          break
        }
      }
      return Promise.reject(error)
    }
  )

  return new Promise<AxiosResponse>((resolve, reject) => {
    ;(async () => {
      const { setHeaderToken = true, ...rest } = options ?? {}
      if (setHeaderToken) {
        // await setHeader(instance)
      }

      if (method === ApiRequestType.GET) {
        return instance.request({
          ...rest,
          url,
          method,
        })
      }
      return instance.request({
        ...rest,
        url,
        method,
      })
    })()
      .then((res: AxiosResponse) => resolve(res))
      .catch((err: AxiosError) => reject(err))
  })
}

interface RequestParameters {
  (endPoint: string, options?: AxiosOptionType): Promise<AxiosResponse>
}

interface ApiCallData {
  get: RequestParameters
  put: RequestParameters
  post: RequestParameters
  patch: RequestParameters
  delete: RequestParameters
}

const requests: ApiCallData = {
  get: (endpoint, options = {}) => request(ApiRequestType.GET, endpoint, options),
  post: (endpoint, options = {}) => request(ApiRequestType.POST, endpoint, options),
  put: (endpoint, options = {}) => request(ApiRequestType.PUT, endpoint, options),
  patch: (endpoint, options = {}) => request(ApiRequestType.PATCH, endpoint, options),
  delete: (endpoint, options = {}) => request(ApiRequestType.DELETE, endpoint, options),
}

export default requests
