import React from 'react'
import { Spin, Typography, Flex } from 'antd'
import { theme } from './theme'

const { Text } = Typography

const OverlayLoader = ({ isLoading, text = '' }: { isLoading: boolean; text?: string }) => {
  if (!isLoading) {
    return null // Do not render anything if isLoading is false
  }

  return (
    <Flex
      vertical
      justify='center'
      align='center'
      gap={'small'}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <Spin
        size='large'
        tip={text}
      />
      <Text style={{ color: theme.colors.secondary, fontSize: '1.2rem' }}>{text}</Text>
    </Flex>
  )
}

export default OverlayLoader
