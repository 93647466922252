import { Layout, Spin } from 'antd'
import styled from 'styled-components'

export const FullScreenSpinner = () => {
  return (
    <SpinnerCard>
      <Spin size='large'></Spin>
    </SpinnerCard>
  )
}

export const ModalSpinner = () => {
  return (
    <SpinnerModalCard>
      <Spin size='large'></Spin>
    </SpinnerModalCard>
  )
}

export const ButtonSpinner = () => {
  return <Spin size='small'></Spin>
}

const SpinnerCard = styled(Layout)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SpinnerModalCard = styled(Layout)`
  width: 100%;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`
