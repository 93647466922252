import styled from 'styled-components'

type ViewerProps = {
  hide?: boolean
  viewer: any
}

function Viewer(props: ViewerProps) {
  const { hide, viewer } = props
  return (
    <ViewerFrameContainer hide={hide}>
      <ViewerFrame
        id='viewer'
        allow={'clipboard-read; clipboard-write'}
        frameBorder='0'
        title='Dicom-Viewer'
        src={viewer}
      />
    </ViewerFrameContainer>
  )
}
export default Viewer

type ViewerFrameContainerType = {
  hide?: boolean
}

const ViewerFrameContainer = styled.div<ViewerFrameContainerType>`
  height: 100%;
  display: ${props => (props.hide ? 'none' : 'block')};
`

const ViewerFrame = styled.iframe`
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
`
