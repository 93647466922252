import React from 'react'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import prodigi from '../Assets/prodigi-trans.png'

const PageNotFound = () => {
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/AiFlow')
  }

  return (
    <div className='card h-screen surface-300'>
      <div className='flex flex-column justify-content-center h-screen md:flex-row'>
        <div className='w-full md:w-3 flex flex-column align-items-center justify-content-center gap-3 py-5 login-container'>
          <img
            src={prodigi}
            className='prodigi-img'
            alt='Prodigi logo'
          />
          <h1 className='text-3xl'>Page Not Found</h1>
          <Button
            label='Go to Home'
            icon='pi pi-home'
            className='w-10rem mx-auto mt-5'
            onClick={goToHome}
          />
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
