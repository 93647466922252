import React from 'react'
import { Button, Flex, Modal, Radio } from 'antd'
import { Form, Formik } from 'formik'
import { Input } from 'antd'
import { theme } from '../shared/theme'

const { TextArea } = Input

type ReworkActionModalProps = {
  is_loading: boolean
  is_open: boolean
  study_id: number
  onSubmit: (study_id: number, reason: string, action: string) => Promise<void>
  onCancel: () => void
}

const ReworkActionModal = (props: ReworkActionModalProps) => {
  const { is_open, is_loading, onSubmit, onCancel, study_id } = props

  return (
    <Modal
      open={is_open}
      title='Rework Action'
      footer={[]}
      onCancel={onCancel}
    >
      <Formik
        initialValues={{
          action: '',
          reason: '',
        }}
        onSubmit={async values => {
          await onSubmit(study_id, values.reason, values.action)
        }}
      >
        {formik => (
          <Form style={{ margin: '1rem' }}>
            <Flex
              gap='middle'
              vertical
            >
              <Radio.Group
                value={formik.values.action}
                onChange={e => formik.setFieldValue('action', e.target.value)}
              >
                <Radio
                  value='VALID'
                  required
                >
                  VALID
                </Radio>
                <Radio
                  value='INVALID'
                  required
                >
                  INVALID
                </Radio>
              </Radio.Group>

              <TextArea
                onChange={e => formik.setFieldValue('reason', e.target.value)}
                placeholder='Reason'
                autoSize={{ minRows: 4, maxRows: 4 }}
                required
              />
            </Flex>
            <Flex
              gap='middle'
              style={{ marginTop: '1rem' }}
              justify='end'
            >
              <Button
                key='cancel'
                type='default'
                danger
                loading={is_loading}
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                key='submit'
                type='primary'
                htmlType='submit'
                loading={is_loading}
                style={{ backgroundColor: theme.colors.appPrimary, color: theme.colors.appBackground }}
              >
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ReworkActionModal
