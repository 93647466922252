import React, { ReactNode } from 'react'
import { Flex, Modal } from 'antd'

const OverlayModal = ({ content, isModalOpen = false }: { content: ReactNode; isModalOpen: boolean }) => {
  return (
    <Flex
      vertical
      justify='center'
      align='center'
      gap={'small'}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
      }}
    >
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
      >
        {content}
      </Modal>
    </Flex>
  )
}

export default OverlayModal
