import { CORE_PLATFORM_URL } from './constants'

export const getAPIEndPoints = Object.freeze({
  auth: {
    login: () => '/login',
    user_status: () => `/user/on-off`,
    get_user_info:(email:string)=>`/user/info?email=${email}`
  },
  cases: {
    pool_count: (userEmail: string | undefined) => `/user/get-pool-count?user_email=${userEmail}`,
    new_case: (userEmail: string | undefined) => `/user/get-next-case?email=${userEmail}`,
    get_case_by_date: (formattedDate: any, userEmail: string | undefined) =>
      `/user/cases?date=${formattedDate}&user=${userEmail}`,
    markCaseNonReportable: (study_id: number) => `/user/mark-non-reportable/${study_id}`,
  },
  reports: {
    get_study: (id: string | undefined) => `/study/${id}`,
    get_report_template: (mod_study: number | string, userEmail: string | undefined) =>
      `/report/templates?mod_study_id=${mod_study}&user_email=${userEmail}`,
    get_dicom_token: (iuid: string | number) => `${CORE_PLATFORM_URL}/dicom/viewer-token?study_iuid=${iuid}`,
    action: () => `/user/action`,
  },
  settings: {
    insertOrUpdate: () => `/redis/item`,
    get: () => '/redis/items',
  },
})
