import React, { useEffect, useMemo, useState } from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, FormOutlined, RetweetOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Layout as AntLayout, Menu } from 'antd'
import Header from './Header'
import { Link } from 'react-router-dom'
import { auth } from '../../Auth/firebaseAuth'
import { ItemType } from 'antd/es/menu/hooks/useItems'

const { Header: AntHeader, Sider, Content } = AntLayout

type Props = {
  children?: React.ReactNode
}

const Layout = (props: Props) => {
  const { children } = props
  const [collapsed, setCollapsed] = useState(true)
  const [userEmail, setUserEmail] = useState<string | undefined>()

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    } else {
      setUserEmail('')
    }
  }, [])

  const menuItems: ItemType[] = [
    {
      key: '1',
      icon: <FormOutlined />,
      label: <Link to={`/AiFlow`}>Active Cases</Link>,
    },
    {
      key: '2',
      icon: <RetweetOutlined />,
      label: <Link to={`/rework`}>Rework Cases</Link>,
    },
    {
      key: '3',
      icon: <SettingOutlined />,
      label: <Link to={`/settings`}>Settings</Link>,
    },
  ]
  const menuItemsWithPermissionsCheck = useMemo(() => {
    if (userEmail) {
      return menuItems.filter(menu => {
        return menu?.key == '2'
          ? [
              'revathi@5cnetwork.com',
              'varshini@5cnetwork.com',
              'ganapriya@5cnetwork.com',
              'anandkumar@5cnetwork.com',
              'naveen@5cnetwork.com',
              'kishore.v@5cnetwork.com',
            ].includes(userEmail)
          : menu?.key == '3'
            ? ['anandkumar@5cnetwork.com', 'ganapriya@5cnetwork.com', 'keerthana@5cnetwork.com'].includes(userEmail)
            : true
      })
    }
  }, [menuItems, userEmail])

  return (
    <AntLayout>
      <Header />
      <AntLayout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: 'auto',
            width: '10vw',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: '8vh',
            background: 'white',
          }}
        >
          <Menu
            theme='dark'
            style={{ background: 'white' }}
            mode='inline'
            defaultSelectedKeys={['1']}
            items={menuItemsWithPermissionsCheck}
          />
        </Sider>
        <AntLayout style={{ marginLeft: collapsed ? 80 : 200, width: '80vw' }}>
          <AntHeader style={{ padding: 0, background: '#ebf3f7' }}></AntHeader>
          <Content
            style={{
              background: '#ebf3f7',
              minHeight: '92vh',
              padding: '1rem',
            }}
          >
            <Button
              type='text'
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                marginBottom: '1rem',
              }}
            />
            {children}
          </Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  )
}

export default Layout
