// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyArVgmAz3jhXA81ib-RPmzeFe9pDqoUVng',
  authDomain: 'aiflow-e041e.firebaseapp.com',
  projectId: 'aiflow-e041e',
  storageBucket: 'aiflow-e041e.appspot.com',
  messagingSenderId: '856936439315',
  appId: '1:856936439315:web:4621b6c60244bf266ba9e6',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
