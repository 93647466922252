import { InFlightNames, InFlightStatuses, InFlightsType } from './types'

export const isInFlightPending = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
    return inFlights[inFlightName]?.status === InFlightStatuses.PENDING
  }
  
  export const isInFlightSuccess = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
    return inFlights[inFlightName]?.status === InFlightStatuses.SUCCESS
  }
  
  export const isInFlightFailed = (inFlights: InFlightsType, inFlightName: InFlightNames) => {
    return inFlights[inFlightName]?.status === InFlightStatuses.ERROR
  }
  