import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '../utils/actionTypes'
import { getAPIEndPoints } from '../../utils/api'
import AxiosInstance from '../AxiosInstance'
import { InFlightNames, InFlightStatuses } from '../../utils/types'
import {
  RequestCaseByDatePayloadType,
  RequestGetCasesInPoolCountPayloadType,
  RequestGetNewCasePayloadType,
  onReceiveGetCasesInPoolCountPayloadType,
  onReceiveGetNewCasePayloadType,
} from './casePayloadTypes'
import { message } from 'antd'

export enum StudyStatuses {
  RECEIVED = 'RECEIVED',
  ASSIGNED = 'ASSIGNED',
  OPENED = 'OPENED',
  IN_POOL = 'IN_POOL',
  WAIT = 'WAIT',
  REPORTABLE = 'REPORTABLE',
  NON_REPORTABLE = 'NON_REPORTABLE',
  DELETED = 'DELETED',
  MODEL_WAIT = 'MODEL_WAIT',
}
export type CaseType = {
  order_id: string
  study_id: number
  patient_name: string
  report_header: string
  created_at: string
  status: StudyStatuses
  mod_study: number
  study_iuid: string
  history: string
  ct_ratio: string | null
}

export interface CaseState {
  case_details: CaseType
  pool_count: number
  inFlights: {
    [InFlightNames.requestCasesInPoolCountInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestNewCaseInFlight]: { status: InFlightStatuses }
    [InFlightNames.requestCaseBasedOnDateInFlight]: { status: InFlightStatuses }
  }
}

const initialState: CaseState = {
  pool_count: 0,
  case_details: {} as CaseType,
  inFlights: {
    [InFlightNames.requestCasesInPoolCountInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestNewCaseInFlight]: { status: InFlightStatuses.INITIAL },
    [InFlightNames.requestCaseBasedOnDateInFlight]: { status: InFlightStatuses.INITIAL },
  },
}

export const requestCasesInPoolCount = createAsyncThunk<
  onReceiveGetCasesInPoolCountPayloadType,
  RequestGetCasesInPoolCountPayloadType
>(Actions.requestCasesInPoolCount, async (data, { rejectWithValue }) => {
  const { email } = data
  try {
    const res = await AxiosInstance.get(getAPIEndPoints.cases.pool_count(email))
    return { pool_count: res.data.pool_count }
  } catch (error) {
    message.error(`Failed to Get count: ${error.message}`)
    return rejectWithValue({})
  }
})

export const requestNewCase = createAsyncThunk<onReceiveGetNewCasePayloadType, RequestGetNewCasePayloadType>(
  Actions.requestNewCase,
  async (data, { rejectWithValue }) => {
    const { email } = data
    try {
      const Case = await AxiosInstance.get(getAPIEndPoints.cases.new_case(email))
      if (Case.data.id === null) {
        message.warning('No Case Found')
      } else {
        window.open(`/Cases/${Case.data.id}`, '_blank')
      }
      return Case.data
    } catch (error) {
      message.error(`Failed to Get new case: ${error.message}`)
      return rejectWithValue({})
    }
  }
)

export const requestCaseBasedOnDate = createAsyncThunk<any, RequestCaseByDatePayloadType>(
  Actions.requestCaseBasedOnDate,
  async (data, { rejectWithValue }) => {
    const { formattedDate, email } = data
    try {
      const response = await AxiosInstance.get(getAPIEndPoints.cases.get_case_by_date(formattedDate, email))
      if (response.data.data.cases.length > 0) {
        const sortedData = response.data.data.cases.sort(
          (a: { status: string; created_at: any }, b: { status: string; created_at: any }) => {
            if (a.status === 'ASSIGNED' && b.status === 'ASSIGNED') {
              return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            } else if (a.status === 'ASSIGNED') {
              return -1
            } else if (b.status === 'ASSIGNED') {
              return 1
            } else if (
              (a.status === 'REPORTABLE' || a.status === 'NON_REPORTABLE') &&
              (b.status === 'REPORTABLE' || b.status === 'NON_REPORTABLE')
            ) {
              return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            } else {
              return 0
            }
          }
        )
        return sortedData
      } else {
        message.warning('No cases Found')
      }
    } catch (error) {
      return rejectWithValue({})
    }
  }
)

const { reducer } = createSlice({
  name: 'cases',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //

      .addCase(requestCasesInPoolCount.pending, state => {
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestCasesInPoolCount.fulfilled, (state, action) => {
        state.pool_count = action.payload.pool_count
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestCasesInPoolCount.rejected, state => {
        state.inFlights.requestCasesInPoolCountInFlight.status = InFlightStatuses.ERROR
      })

      .addCase(requestNewCase.pending, state => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestNewCase.fulfilled, (state, action) => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestNewCase.rejected, state => {
        state.inFlights.requestNewCaseInFlight.status = InFlightStatuses.ERROR
      })

      .addCase(requestCaseBasedOnDate.pending, state => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.PENDING
      })
      .addCase(requestCaseBasedOnDate.fulfilled, (state, action) => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.SUCCESS
      })
      .addCase(requestCaseBasedOnDate.rejected, state => {
        state.inFlights.requestCaseBasedOnDateInFlight.status = InFlightStatuses.ERROR
      })
  },
})

export default reducer
